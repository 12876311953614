 body {
   background-color: #062743;
   font-family: "Ubuntu", sans-serif;
   background: url(background.jpg) no-repeat center center fixed; 
   -webkit-background-size: cover;
   -moz-background-size: cover;
   -o-background-size: cover;
   background-size: cover;
   }
 .bg-dark-blue {
    /* background-color: rgb(2, 12, 24); */
    background-color: #113a5d;
 }
 .Appdiv {
   background-color: #113a5d;
 }
 .navbar-collapse{
   text-align: right !important;
   align-items: right !important;
}

 .dropdown .dropdown-item {
    background-color: #113a5d;
    color: #f9f9f9 !important;
 }
 .myName{
   font-family: "Audiowide", sans-serif;
   text-align: left;
   text-shadow: 3px 3px 3px #ababab;
   font-size: 60px;
   padding: 5px;
   padding-left: 10px;
 }
 .addressTable{
   width: 98%;
   margin-left: 10px;
   text-align: left;
   border-top: 2px;
   border-bottom: 0px;
   border-left: 0px;
   border-right: 0px;
   border-color: #062743;
   border-style: outset;
 }
 .cv_table{
   width: 98%;
   margin-left: 10px;
   text-align: left;
   border-left: 0px;
   border-right: 0px;
   border-top: 0px;
 }
.indented_td{
   padding-left: 20px;
   padding-right: 20px;
}
.section_cells{
   text-align: left;
   padding-left: 10px;
   border-top: 0px;
   border-bottom: 2px;
   border-color: #062743;
   border-style: outset;
}
.section_title{
   font-style: initial;
   font-size: 38px;
   font-family: "Audiowide", sans-serif;
}
.experience{
   text-align: left;
}
.dates{
   text-align: right;
   padding-right: 30px;
}
 .me_img{
   height: 220px;
   width: 180px;
   margin-right: 20px;
   margin-top: 20px;
   float: right;
 }
 .dropdown-item:hover { 
   background-color: #062743 !important;
 }
.mainbody {
   text-align: center;
   margin: auto;
   background-color: #f9f9f9;
   border-radius: 5px;
   color:#062743 !important;
   opacity: 0.87;
}
.dropdown-menu{
   background-color: #113a5d !important;
   color: #f9f9f9 !important;
   box-shadow: 1px 1px 2px #062743;
}
.nav_spacer {
   max-width: 80px;
   min-width: 40px;

}

.contentdiv {
   max-width: 1900px;
   min-width: 900px;
   background-color: transparent;
   margin: auto;
   padding: 6px;
   font-family: "Ubuntu", sans-serif;
}
.logoimg {
    height: 45px;
    width: 90px;
    margin-left: 30px;
}
.navigation_bar{
   text-align: right;
   align-self: start;
   text-align: right;
   color: #f9f9f9 !important;
   float: right;
}
 .navbar{
   max-width: 1900px;
   min-width: 900px;
   margin: auto;
}

/* 
#062743
#113a5d
#c4ffdd
#f9f9f9
*/